/* eslint-disable max-len */
const React = require('react');
const Page = require('nordic/page');
const { ErrorScreen } = require('@andes/technical-error');
const Script = require('nordic/script');
const { useI18n } = require('nordic/i18n');
const HeadView = require('../../components/HeadView');
const HeaderView = require('../../components/HeaderView');
const Footer = require('../../components/FooterView');
const CommunityView = require('../../components/CommunityView');

const Error404Page = (props) => {
  const { i18n } = useI18n();
  const { user, refererURL, siteId, categories, location, userLogged } = props;

  return (
    <Page name="notFound" state={props}>
      <HeadView i18n={i18n} location={location} />
      <HeaderView i18n={i18n} user={user} refererURL={refererURL} siteId={siteId} categories={categories} location={location} userLogged={userLogged} />

      <div className="section-error">
        <ErrorScreen
          title={i18n.gettext('Parece que esta página no existe.')}
          action={{
            text: i18n.gettext('Ir a la página principal'),
            onClick: () => {
              window.location.href = `/${location}`;
            },
          }}
        />
      </div>

      <CommunityView i18n={i18n} siteId={siteId} user={user} location={location} />
      <Footer i18n={i18n} user={user} refererURL={refererURL} siteId={siteId} location={location} userLogged={userLogged} />

      <Script src="vendor.js" />
    </Page>
  );
};

/**
 * Expose Error404Page
 */
module.exports = Error404Page;
