/* global window, document */
/**
 * Styles
 */
require('../pages/notfound/error.scss');

/**
 * Polyfills
 */
require('./polyfills');

/**
 * Module dependencies
 */
const React = require('react');
const { AndesProvider } = require('@andes/context');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { hydrateRoot } = require('nordic/hydrate');
const NotFoundView = require('../pages/notfound/NotFoundView');

/**
 * Get server state
 */
const preloadedState = window.__PRELOADED_STATE__;
const { locale, device, translations, csrfToken } = preloadedState;

const i18n = new I18n({
  translations,
});

hydrateRoot(
  document.getElementById('root-app'),
  <AndesProvider locale={locale} device={device} csrfToken={csrfToken}>
    <I18nProvider i18n={i18n}>
      <NotFoundView {...preloadedState} />
    </I18nProvider>
  </AndesProvider>,
);
