/* eslint-disable nordic/i18n-jsx-no-literals */

const React = require('react');
const { useI18n } = require('nordic/i18n');
const config = require('../../../config/default');
const LanguageSelector = require('../LanguageSelector');

const getAppstoreBySiteID = (siteId) => {
  switch (siteId) {
    case 'MLB':
      return 'https://centraldeparceiros.mercadolivre.com.br';
    case 'MLA':
      return 'https://centrodepartners.mercadolibre.com.ar';
    case 'MCO':
      return 'https://centrodepartners.mercadolibre.com.co';
    case 'MLM':
      return 'https://centrodepartners.mercadolibre.com.mx';
    case 'MLU':
      return 'https://centrodepartners.mercadolibre.com.uy';
    case 'MLC':
      return 'https://centrodepartners.mercadolibre.cl';
    case 'MLV':
      return 'https://centrodepartners.mercadolibre.com';
    case 'MPE':
      return 'https://centrodepartners.mercadolibre.com.pe';
    default:
      return 'https://centrodepartners.mercadolibre.com';
  }
};

const FooterView = ({ location, siteId, user, refererURL, isCBT, userLogged }) => {
  const { i18n } = useI18n();
  const text = config.defaultText[location];
  const siteConfig = config.fendConfig[siteId];

  let userInfo;
  const date = new Date();

  const termsAndConditions = () => {
    switch (siteId) {
      case 'MLB':
        return 'https://developers.mercadolivre.com.br/pt_br/termos-e-condicoes';
      case 'MLM':
        return 'https://developers.mercadolibre.com.mx/es-mx-terminos-y-condiciones';
      case 'MLA':
        return 'https://developers.mercadolibre.com.ar/es-ar-terminos-y-condiciones';
      case 'MLC':
        return 'https://developers.mercadolibre.cl/es-cl-terminos-y-condiciones';
      case 'MCO':
        return 'https://developers.mercadolibre.com.co/es-co-terminos-y-condiciones';
      case 'MLU':
        return 'https://developers.mercadolibre.com.uy/es-uy-terminos-y-condiciones';
      case 'MPE':
        return 'https://developers.mercadolibre.com.pe/es-pe-terminos-y-condiciones';
      default:
        return 'https://developers.mercadolibre.com.ar/es_ar/terminos-y-condiciones';
    }
  };

  const getSiteByLocationBugBounty = () => {
    switch (location) {
      case 'pt_br':
        return 'https://developers.mercadolivre.com.br/pt_br/security-researchers-br';
      case 'en_us':
        return 'https://developers.mercadolibre.com.ar/en_us/security-researchers';
      case 'es_ar':
      default:
        return 'https://developers.mercadolibre.com.ar/es_ar/security-researchers-esp';
    }
  };

  const getSiteByLocationReport = () => {
    switch (location) {
      case 'pt_br':
        return 'https://forms.gle/KZphgzp9Dj2zhr9k8';
      case 'en_us':
        return 'https://forms.gle/7pd4KpvTP7NU36fj9';
      case 'es_ar':
      default:
        return 'https://forms.gle/byVdt26WiP85MgYy9';
    }
  };

  const urlMap = {
    MLB: 'https://developers.mercadolivre.com.br/pt_br/developer-partner-program?nocache=true',
    MLA: 'https://developers.mercadolibre.com.ar/es_ar/developer-partner-program?nocache=true',
    MLM: 'https://developers.mercadolibre.com.mx/es_ar/developer-partner-program?nocache=true',
    MCO: 'https://developers.mercadolibre.com.co/es_ar/developer-partner-program?nocache=true',
    CBT: 'https://global-selling.mercadolibre.com/devsite/developer-partner-program-global-selling?nocache=true',
    MLU: 'https://developers.mercadolibre.com.uy/es_ar/developer-partner-program?nocache=true',
    MLC: 'https://developers.mercadolibre.cl/es_ar/developer-partner-program?nocache=true',
    MPE: 'https://developers.mercadolibre.com.pe/es_ar/developer-partner-program?nocache=true',
  }[siteId || 'MLA'];

  const joinAsText = `${i18n.gettext('Ingresaste como')} <span> ${user?.nickname} </span>`;
  const copyRightText = `Copyright © ${date.getFullYear()} ${text?.commons?.companyName}`;

  if (userLogged) {
    userInfo = (
      <div className="user-name with-padding">
        {joinAsText}
      </div>
    );
  } else {
    userInfo = (
      <div className="user-name">
        <ul>
          <li>
            <a href={`${siteConfig.url.registration}?confirmation_url=${refererURL}`}>
              {i18n.gettext('Regístrate')}
            </a>
          </li>
          <li>
            <a href={`https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/login?platform_id=ml&go=${refererURL}`}>
              {i18n.gettext('Ingresar')}
            </a>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <footer className="nav-footer">
      <div className="nav-footer-wrapper">
        <div className="nav-footer-language">
          <div className="desktop-only">
            <a
              className="nav-footer-language__link"
              href={getSiteByLocationReport()}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {i18n.gettext('Denunciar')}
            </a>
            <a
              className="nav-footer-language__link"
              href={getSiteByLocationBugBounty()}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Security Researchers
            </a>
            {!isCBT && (
              <a
                className="nav-footer-language__link"
                href={getAppstoreBySiteID(siteId)}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {siteId === 'MLB' ? 'Central de Parceiros' : 'Centro de Partners'}
              </a>
            )}
          </div>
          {!isCBT && <LanguageSelector i18n={i18n} location={location} />}
        </div>
        {userInfo}
        <div className="nav-footer-copyright">
          <p className="nav-footer-copyright__text">
            {copyRightText}
          </p>
        </div>
        <div className="nav-footer-navigation">
          <ul className="nav-footer-navigation__list">
            <li className="nav-footer-navigation__items">
              {
                siteId && (
                  <a href={
                    location === 'en_us'
                      ? 'https://global-selling.mercadolibre.com/devsite/mercado-libre-global-selling-developer-terms-and-conditions'
                      : termsAndConditions()
                  }
                  >{i18n.gettext('Términos y condiciones')}
                  </a>
                )
              }
            </li>
            <li className="nav-footer-navigation__items">
              <a href={siteConfig.footer.url.privacy}>{i18n.gettext('Cómo cuidamos tu privacidad')}</a>
            </li>
            <li className="nav-footer-navigation__items">
              {urlMap && <a href={urlMap}>Developer Partner Program</a>}
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

module.exports = FooterView;
