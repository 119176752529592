const React = require('react');
const { injectI18n } = require('nordic/i18n');

class CommunityView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { i18n } = this.props;

    return (
      <section className="community">
        <div className="mesh-row">
          <div className="mesh-col-l-6 community__content-text">
            <h4 className="community__title">{i18n.gettext('Forma parte de nuestra comunidad')}</h4>
          </div>
        </div>
      </section>
    );
  }
}

module.exports = injectI18n(CommunityView);
